(function () {
  var scriptURL =
    "https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js";
  if (window.ShopifyBuy) {
    if (window.ShopifyBuy.UI) {
      ShopifyBuyInit();
    } else {
      loadScript();
    }
  } else {
    loadScript();
  }
  function loadScript() {
    var script = document.createElement("script");
    script.async = true;
    script.src = scriptURL;
    (
      document.getElementsByTagName("head")[0] ||
      document.getElementsByTagName("body")[0]
    ).appendChild(script);
    script.onload = ShopifyBuyInit;
  }
  function ShopifyBuyInit() {
    var client = ShopifyBuy.buildClient({
      domain: "bafic.myshopify.com",
      storefrontAccessToken: "0ca4428e56d42df13303bb66771f2e4b",
    });

    ShopifyBuy.UI.onReady(client).then(function (ui) {
      ui.createComponent("product", {
        id: "7905680457883",
        node: document.getElementById("product-component-7905680457883"),
        moneyFormat: "%C2%A3%7B%7Bamount%7D%7D",
        options: {
          product: {
            iframe: false,
            width: "1500px",
            buttonDestination: "checkout",
            contents: {
              img: false,
              title: true,
              price: true,
              description: true,
              imgWithCarousel: true,
            },
            text: {
              button: "Buy",
              outOfStock: "Sold Out",
              unavailable: "Unavailable",
            },
          },
          productSet: {},
          modalProduct: {},
          option: {},
          cart: {
            startOpen: false,
            iframe: false,
          },
          toggle: {
            startOpen: false,
            iframe: false,
          },
        },
      });

      // Fetch a single product by ID
      const productId = "gid://shopify/Product/7905680457883";

      client.product.fetch(productId).then((product) => {
        // Do something with the product
        if (product && product.availableForSale) {
          document.querySelector("#productButton").textContent = "Buy T-Shirt";
        }
      });
    });
  }
})();

document.querySelector("#productButton").addEventListener("click", () => {
  document.body.classList.add("showProduct");
});

document.querySelector("#webcamButton").addEventListener("click", (event) => {
  document.querySelector("#webcamButton").textContent = "Loading...";
  cocoSsd.load().then(function (loadedModel) {
    model = loadedModel;
    // Show demo section now model is ready to use.
    enableCam(event);
  });
});

/**
 * @license
 * Copyright 2018 Google LLC. All Rights Reserved.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * =============================================================================
 */

/********************************************************************
 * Source originally created by Jason Mayes 2020.
 ********************************************************************/

/********************************************************************
// Continuously grab image from webcam stream and classify it.
********************************************************************/

const cameraSection = document.getElementById("camera");
const video = document.getElementById("webcam");
const cameraLive = document.getElementById("cameraLive");

let model = undefined;

// Check if webcam access is supported.
function hasGetUserMedia() {
  return !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia);
}

// Keep a reference of all the child elements we create
// so we can remove them easilly on each render.
var children = [];

// // If webcam supported, add event listener to button for when user
// // wants to activate it.
// if (hasGetUserMedia()) {
//   const enableWebcamButton = document.getElementById("webcamButton");
//   enableWebcamButton.addEventListener("click", enableCam);
// } else {
//   console.warn("getUserMedia() is not supported by your browser");
// }

// Enable the live webcam view and start classification.
function enableCam(event) {
  if (!model) {
    console.log("Wait! Model not loaded yet.");
    return;
  }

  // Hide the button.
  event.target.classList.add("removed");

  // getUsermedia parameters.
  const constraints = {
    audio: false,
    video: {
      facingMode: "user",
    },
  };

  document.getElementById("webcamButton").classList.add("invisible");

  // Webcam stream.
  navigator.mediaDevices.getUserMedia(constraints).then(function (stream) {
    video.addEventListener("loadeddata", predictWebcam);
    video.srcObject = stream;
    video.onloadedmetadata = function (e) {
      video.play();
    };
  });
}

function predictWebcam() {
  // Now let's start classifying the stream.
  model.detect(video).then(function (predictions) {
    // Remove any highlighting we did previous frame.
    for (let i = 0; i < children.length; i++) {
      cameraLive.removeChild(children[i]);
    }
    children.splice(0);

    // Now lets loop through predictions and draw them to the live view if
    // they have a high confidence score.
    for (let n = 0; n < predictions.length; n++) {
      // If we are over 66% sure we are sure we classified it right, draw it!
      if (predictions[n].score > 0.66) {
        const p = document.createElement("p");
        // console.log(predictions[n].bbox[0] * 100 / 800)
        p.innerText = predictions[n].class;
        // Draw in top left of bounding box outline.
        p.style =
          "left: " +
          (predictions[n].bbox[0] * 100) / video.videoWidth +
          "%;" +
          "top: " +
          (predictions[n].bbox[1] * 100) / video.videoHeight +
          "%;" +
          "width: " +
          ((predictions[n].bbox[2] - 3.5) * 100) / video.videoWidth +
          "%;";

        // Draw the actual bounding box.
        const highlighter = document.createElement("div");
        highlighter.setAttribute("class", "highlighter");
        highlighter.style =
          "left: " +
          (predictions[n].bbox[0] * 100) / video.videoWidth +
          "%; top: " +
          (predictions[n].bbox[1] * 100) / video.videoHeight +
          "%; width: " +
          (predictions[n].bbox[2] * 100) / video.videoWidth +
          "%; height: " +
          (predictions[n].bbox[3] * 100) / video.videoHeight +
          "%;";

        cameraLive.appendChild(highlighter);
        cameraLive.appendChild(p);

        // Store drawn objects in memory so we can delete them next time around.
        children.push(highlighter);
        children.push(p);
      }
    }

    // Call this function again to keep predicting when the browser is ready.
    window.requestAnimationFrame(predictWebcam);
  });
}
